import React from "react";
import styled from "styled-components";
import {
  InputGroup,
  InputValidateGroup,
} from "../../components/inputs/InputGroups";
import { Link } from "react-router-dom";
import { primary4, grey1, grey3, grey5, error3 } from "../../constants/color";
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen } from "../../atom/Atoms";
import AlertModal from "../../components/modals/AlertModal";

const LoginMobile = () => {
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  return (
    <Wrapper>
      <Box>
        <WrapTitle>
          <Title>Sign IN!!</Title>
          Enjoy LCK by LCK Fantasy
        </WrapTitle>
        <InputWrapper>
          <SubTitle>Email</SubTitle>
        </InputWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InputValidateGroup placeholder="Email" />
        </div>
        <InputWrapper>
          <SubTitle>Password</SubTitle>
        </InputWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InputValidateGroup placeholder="비밀번호를 입력해주세요" />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button>로그인</Button>
        </div>
        <LoginWrapper>
          <SubTitle
            style={{
              width: 230,
              height: 50,
              paddingTop: 10,
              alignItems: "center",
            }}
          >
            회원이 아니신가요?
          </SubTitle>
          <LoginButton
            style={{
              width: 150,
              alignItems: "center",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                marginTop: "8px",
              }}
              to={"/signup"}
            >
              Sign UP
            </Link>
          </LoginButton>
        </LoginWrapper>
      </Box>
      {/* alert */}
      {isAlertOpen && <AlertModal></AlertModal>}
      {/* alert */}
    </Wrapper>
  );
};

export default LoginMobile;
const Box = styled.div`
  background: white;
  width: 100%;
  font-family: "Dohyeon";
`;
const Wrapper = styled.div`
  width: 100%;
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: white;
`;
const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: white;
`;
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;
const WrapWriteToken = styled.div`
  background: #f0f0f0;
  padding: 8px;
`;
const TokenMsg = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  display: block;
  border: none;
  width: 80%;
  margin: 10px;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
  background: ${(props) => (props.inactive ? `#BFBFBF` : null)};
  background: ${(props) => (props.active ? `#434343` : null)};
  color: ${(props) => (props.inactive ? `black` : "#434343")};
  border: 1px solid ${(props) => (props.inactive ? `grey` : "#434343")};
  cursor: ${(props) => (props.inactive ? `default` : null)};
  &:hover {
  }
`;
const LoginButton = styled.button`
  display: block;
  border: none;
  width: 30%;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
`;
const WrapTitle = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  font-family: "Dohyeon";
  line-height: 20px;
  font-size: 13px;
  &::after {
    display: block;

    content: "";
    left: 0;
    width: 100%;
    height: 1px;
    background: #8c8c8c;
  }
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: black;
  word-break: break-all;
`;
const SubTitle = styled.div`
  font-size: 20px;
  width: 20%;
  color: black;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;
const Team = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;
