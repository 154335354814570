import React, { useEffect, useState } from "react";
import PageLayout from "../../templates/PageLayout";
import LaneList from "../../atom/LaneList";
import { MyTeam } from "../../atom/Atoms";
import styled from "styled-components";
import unknown from "../../assets/images/select-removebg.png";
import Lane from "../../components/Lane";
import DraftScore from "../../components/DraftScore";
import { instanceAxios } from "../../api/axios";
import { useRecoilState } from "recoil";
import { Link, useLocation } from "react-router-dom";
import backGround from "../../assets/images/background.png";
import { primary4, grey1, grey3, grey5, error3 } from "../../constants/color";

const DraftMobile = () => {
  const location = useLocation();
  const fantasyFocus = location.pathname.split("/")[3];
  const [laneOpen, setLaneOpen] = useState({
    top: true,
    jug: false,
    mid: false,
    bot: false,
    sup: false,
  });
  const [laneSelect, setLaneSelect] = useState("");
  const [playerList, setPlayerList] = useState([]);
  const [myTeam, setMyTeam] = useRecoilState(MyTeam);
  const onLaneClick = (lane) => {
    if (lane === "TOP") {
      laneOpen.top = true;
      laneOpen.jug = false;
      laneOpen.mid = false;
      laneOpen.bot = false;
      laneOpen.sup = false;
      setLaneSelect("TOP");
    } else if (lane === "JUG") {
      laneOpen.top = false;
      laneOpen.jug = true;
      laneOpen.mid = false;
      laneOpen.bot = false;
      laneOpen.sup = false;
      setLaneSelect("JUG");
    } else if (lane === "MID") {
      laneOpen.top = false;
      laneOpen.jug = false;
      laneOpen.mid = true;
      laneOpen.bot = false;
      laneOpen.sup = false;
      setLaneSelect("MID");
    } else if (lane === "ADC") {
      laneOpen.top = false;
      laneOpen.jug = false;
      laneOpen.mid = false;
      laneOpen.bot = true;
      laneOpen.sup = false;
      setLaneSelect("ADC");
    } else if (lane === "SUP") {
      laneOpen.top = false;
      laneOpen.jug = false;
      laneOpen.mid = false;
      laneOpen.bot = false;
      laneOpen.sup = true;
      setLaneSelect("SUP");
    }
    console.log("onLaneClick", laneOpen);
  };
  useEffect(() => {
    const getPlayer = async () => {
      try {
        const response = await instanceAxios.get("/player");
        if (response.status === 200) {
          setPlayerList(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPlayer();
    setMyTeam({
      top: { points: 0, Score: 0 },
      jug: { points: 0, Score: 0 },
      mid: { points: 0, Score: 0 },
      adc: { points: 0, Score: 0 },
      sup: { points: 0, Score: 0 },
    });
    console.log(myTeam, "팀");
  }, []);
  useEffect(() => {
    console.log(myTeam.top.points, "마이팀");
  }, [myTeam]);
  const resetTeam = () => {
    setMyTeam({
      top: { points: 0, name: "", team: "" },
      jug: { points: 0, name: "", team: "" },
      mid: { points: 0, name: "", team: "" },
      adc: { points: 0, name: "", team: "" },
      sup: { points: 0, name: "", team: "" },
    });
  };
  return (
    <>
      <PageLayout subtitle="draft"></PageLayout>
      <DraftWapper>
        <ButtonWrapper
          style={{
            paddingTop: 25,
            backgroundImage: `url(${backGround})`,
          }}
        >
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"/page/Fantasy"}
          >
            <FilterButton>Fantasy</FilterButton>
          </Link>
          <FilterCheckedButton>Draft</FilterCheckedButton>
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"/page/Fantasy/standings"}
          >
            <FilterButton>Standings</FilterButton>
          </Link>
        </ButtonWrapper>
        <PageWapper>
          <DraftScore></DraftScore>
          <WapperLink>
            <List>
              {LaneList.map(({ lane, path }) => {
                return (
                  <Linker onClick={() => onLaneClick(lane)} key={lane}>
                    <img height={44} src={`${path}`} alt={`${lane}`} />
                  </Linker>
                );
              })}
              <Linker onClick={resetTeam}></Linker>
            </List>
          </WapperLink>
        </PageWapper>
      </DraftWapper>
    </>
  );
};

export default DraftMobile;
const DraftWapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
`;
const Wapper = styled.div`
  width: 600px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
`;
const PageWapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;
const WapperLink = styled.div`
  width: 100%;
  float: bottom;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;
const WapperLane = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Linker = styled.div`
  padding-top: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const LaneListWrapper = styled.div`
  display: flex;
  width: 300px;
`;
const NavFantasy = styled.div`
  display: flex;
  padding-left: 350px;
  width: 100%;
  flex-direction: row;
  text-decoration: none;
  color: #a6a6b9;
  height: 65px;
  font-family: "lckEN";
`;
const NavbottomContent = styled.div`
  padding: 8px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #4f3a6c;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 13px;
  }
`;
const NavbottomFocusContent = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  color: #4f3a6c;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 13px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  background-image: url(backGround);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
const FilterButton = styled.button`
  display: block;
  border: none;
  width: 100px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${primary4};
  color: ${grey1};
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  font-family: "Dohyeon";
  &:hover {
    background: ${primary4};
    color: ${grey1};
  }
`;
const FilterCheckedButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  height: 60px;
  margin-left: 15px;
  z-index: 10;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  background: ${grey1};
  color: ${primary4};
  font-family: "Dohyeon";
`;
