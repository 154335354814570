import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import styled from "styled-components";
import Nav from "./components/Nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { useMediaQuery } from "react-responsive";
import News from "./pages/News";
import Schedule from "./pages/Schedule";
import Video from "./pages/Video";
import Stats from "./pages/Stats";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Fantasy from "./pages/fantasy/Fantasy";
import Draft from "./pages/fantasy/Draft";
import Ranking from "./pages/fantasy/Ranking";
import Footer from "./components/Footer";

// 모바일 컴포넌트
import NavMobile from "./components/mobile/NavMobile";
import ScheduleMobile from "./pages/mobiles/ScheduleMobile";
import SignUpMobile from "./pages/mobiles/SignUpMobile";
import LoginMobile from "./pages/mobiles/LoginMobile";
import FantasyMobile from "./pages/mobiles/FantasyMobile";
import DraftMobile from "./pages/mobiles/DraftMobile";
import RankingMobile from "./pages/mobiles/RankingMobile";

function App() {
  const isDesktop = useMediaQuery({ minWidth: 820 });
  return (
    <>
      <RecoilRoot>
        <Router>
          {isDesktop ? (
            <>
              <Header></Header>
              <Nav></Nav>
              <Routes>
                <Route path="/SignUp" element={<SignUp />}></Route>
                <Route path="/" element={<Home />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/page/Fantasy" element={<Fantasy />}>
                  <Route path="standings" element={<Ranking />} />
                </Route>
                {/* <Route path="/page/Video" element={<Video />}></Route>
                <Route path="/page/News" element={<News />}></Route> */}
                <Route path="/page/Stats" element={<Stats />}></Route>
                <Route path="/page/Schedule" element={<Schedule />}></Route>
              </Routes>
              <Footer></Footer>
            </>
          ) : (
            <>
              <NavMobile></NavMobile>
              <Routes>
                <Route path="/SignUp" element={<SignUpMobile />}></Route>
                <Route path="/" element={<Home />}></Route>
                <Route path="/login" element={<LoginMobile />}></Route>
                <Route path="/page/Fantasy" element={<FantasyMobile />}>
                  <Route path="draft" element={<DraftMobile />} />
                  <Route path="standings" element={<RankingMobile />} />
                </Route>
                {/* <Route path="/page/Video" element={<Video />}></Route>
                <Route path="/page/News" element={<News />}></Route> */}
                <Route path="/page/Stats" element={<Stats />}></Route>
                <Route
                  path="/page/Schedule"
                  element={<ScheduleMobile />}
                ></Route>
              </Routes>
              <Footer></Footer>
            </>
          )}
        </Router>
      </RecoilRoot>
    </>
  );
}

export default App;
