import styled from "styled-components";
import React from "react";
const Input = styled.input`
  width: 95%;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid grey;
  touch-action: manipulation;
  ime-mode:disabled;
  
  &:focus {
    border: 1px solid #4f3a6c;
  }

  &::placeholder {
    color: "#AFAFAF";
  }
`;

export const InputValidateGroup = ({
  type = "text",
  placeholder = "",
  value,
  setValue,
  keyDown,
  readonly = false,
}) => {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      readOnly={readonly}
      onChange={setValue}
      onKeyDown={keyDown}
    />
  );
};
export const InputPasswordValidateGroup = ({
  type = "password",
  placeholder = "",
  value,
  setValue,
  readonly = false,
}) => {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      readOnly={readonly}
      onChange={setValue}
    />
  );
};

export const InputGroup = ({
  type = "text",
  placeholder = "",
  value,
  setValue,
  readonly = false,
}) => {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      readOnly={readonly}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
