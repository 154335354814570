import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const listPageReLoading = atom({
  key: "listPageReLoading", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

const focusNav = atom({
  key: "focusNav", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
const MyTeam = atom({
  key: "MyTeam", // unique ID (with respect to other atoms/selectors)
  default: {
    top: { points: 0, Score: 0 },
    jug: { points: 0, Score: 0 },
    mid: { points: 0, Score: 0 },
    adc: { points: 0, Score: 0 },
    sup: { points: 0, Score: 0 },
  }, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

// Alert
const IsAlertOpen = atom({
  key: "AlertModalOpen", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const AlertMessage = atom({
  key: "AlertMessage", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export { listPageReLoading, focusNav, MyTeam, IsAlertOpen, AlertMessage };
