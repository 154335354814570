import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MyTeam } from "../atom/Atoms";
import { useRecoilState } from "recoil";

const DraftScore = () => {
  const [score, setScore] = useState(0);
  const [member, setMember] = useState(0);
  const [myTeam, setMyTeam] = useRecoilState(MyTeam);
  useEffect(() => {
    setScore(
      myTeam.top.points +
        myTeam.jug.points +
        myTeam.mid.points +
        myTeam.adc.points +
        myTeam.sup.points
    );
  }, [myTeam]);
  return (
    <Wrapper>
      <h1>Points : {score}</h1>
      <h1>잔여 Points : {100 - score}</h1>
      <h1>MyTeam : </h1>
      <h1>member : {} / 5 </h1>
    </Wrapper>
  );
};

export default DraftScore;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
`;
