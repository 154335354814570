import React from "react";
import PageLayout from "../../templates/PageLayout";
import LaneList from "../../atom/LaneList";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import backGround from "../../assets/images/background.png";
import { primary4, grey1, grey3, grey5, error3 } from "../../constants/color";

const Ranking = () => {
  const isDesktop = useMediaQuery({ minWidth: 820 });
  const currentDate = new Date("2023-06-06");

  // 주차 계산 함수
  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7) - 19;
  }

  // 현재 주차 출력
  const currentWeek2 = getWeekNumber(currentDate);
  function navRander() {
    return (
      <NavFantasy>
        <Link
          style={{
            textDecoration: "none",
            color: "#a6a6b9",
            marginTop: "8px",
          }}
          to={"/page/Fantasy"}
        >
          <NavbottomContent>fantasy</NavbottomContent>
        </Link>
        <NavbottomFocusContent>standings</NavbottomFocusContent>
      </NavFantasy>
    );
  }
  function MobilenavRander() {
    return (
      <ButtonWrapper
        style={{
          paddingTop: 25,
          backgroundImage: `url(${backGround})`,
        }}
      >
        <Link
          style={{
            textDecoration: "none",
          }}
          to={"/page/Fantasy"}
        >
          <FilterButton>Fantasy</FilterButton>
        </Link>
        <Link
          style={{
            textDecoration: "none",
          }}
          to={"/page/Fantasy/draft"}
        >
          <FilterButton>Draft</FilterButton>
        </Link>
        <FilterCheckedButton>Standings</FilterCheckedButton>
      </ButtonWrapper>
    );
  }
  return (
    <>
      <Wrapper>
        {isDesktop && navRander()}
        {!isDesktop && MobilenavRander()}
        <RankingSheet>
          <Tabs>Standings</Tabs>
          <Tabs>WEEK {currentWeek2}</Tabs>
          <Tabs>
            <No> No</No>
            <QuizAnswer2> Team</QuizAnswer2>
            <UserAnswer> Score</UserAnswer>
          </Tabs>
        </RankingSheet>
      </Wrapper>
    </>
  );
};

export default Ranking;
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;
const RankingSheet = styled.div`
  height: 100vh;
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Tabs = styled.div`
  display: flex;
  font-size: 24px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 30px;
  align-items: center;
  border-bottom: 1px solid gray;
`;

const Quizzes = styled.div`
  display: flex;
  width: 100%;
  flex: 0 0 30px;
`;

const TradeOdd = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  width: 100%;
  height: 23px;
  background-color: rgb(232, 246, 255);
`;
const TradeEven = styled.div`
  display: flex;
  font-size: 14px;
  height: 23px;
`;

const No = styled.div`
  width: 100%;
  text-align: center;
`;

const UserAnswer = styled.div`
  width: 100%;
  text-align: center;
`;

const QuizAnswer2 = styled.div`
  width: 100%;
  text-align: center;
`;

const Rise = styled.div`
  color: #d60000;
`;
const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FlexColumn2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`;
const Header = styled.div`
  font-size: 30pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DoHyeon-Regular";
`;

const ButtonGroups = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  font-family: "DoHyeon-Regular";
`;
const NavFantasy = styled.div`
  padding-left: 350px;
  display: flex;
  width: 100%;
  flex-direction: row;
  text-decoration: none;
  color: #a6a6b9;
  height: 65px;
  font-family: "lckEN";
`;
const NavbottomContent = styled.div`
  padding: 8px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #4f3a6c;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 13px;
  }
`;
const NavbottomFocusContent = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  color: #4f3a6c;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 13px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  background-image: url(backGround);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
const FilterButton = styled.button`
  display: block;
  border: none;
  width: 100px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${primary4};
  color: ${grey1};
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  font-family: "Dohyeon";
  &:hover {
    background: ${primary4};
    color: ${grey1};
  }
`;
const FilterCheckedButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  height: 60px;
  margin-left: 15px;
  z-index: 10;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  background: ${grey1};
  color: ${primary4};
  font-family: "Dohyeon";
`;
