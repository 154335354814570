import React from "react";
import PageLayout from "../templates/PageLayout";

const Stats = () => {
  return (
    <PageLayout>
      <div></div>
    </PageLayout>
  );
};

export default Stats;
