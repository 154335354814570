import React, { useEffect, useState } from "react";
import styled from "styled-components";
import summer from "../assets/images/summerlogo.png";
import youtube from "../assets/images/youtube.png";
import afreecatv from "../assets/images/afreeca.png";
import naver from "../assets/images/naver.png";
import { instanceAxios } from "../api/axios";
import { useMediaQuery } from "react-responsive";
const Home = () => {
  const isDesktop = useMediaQuery({ minWidth: 1120 });
  const [count, setCount] = useState();
  const [gameDay, setGameDay] = useState(true);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    //오늘 경기 데이터 불러오기
    const getSchedule = async () => {
      try {
        const response = await instanceAxios.get("/schedule/today");
        if (response.status === 200) {
          setSchedule(response.data);

          //경기 없을 시 렌더 X 조건 함수
          if (response.data.length < 1) {
            setGameDay(false);
          }
          console.log(response.data, "schedule");
        }
      } catch (err) {
        setGameDay(false);
        console.error(err);
      }
    };
    getSchedule();
    const interval = setInterval(() => {
      const currentTime = new Date();
      let targetTime;
      // 수,목,금은 오후 5시로 설정
      if (currentTime.getDay() >= 3 && currentTime.getDay() <= 5) {
        targetTime = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          17, // 오후 5시
          0, // 분
          0 // 초
        );
      }
      // 토,일은 오후 3시로 설정
      else if (currentTime.getDay() === 6 || currentTime.getDay() === 0) {
        targetTime = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          15, // 오후 3시
          0, // 분
          0 // 초
        );
      }
      const remainingTime = targetTime - currentTime;

      if (remainingTime > 0) {
        setCount(remainingTime);
      } else {
        setCount(0);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const openInsta = (url) => {
    window.open(url, "_blank");
  };
  // 카운트다운 초시계 포맷
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  // 경기날 렌더링 함수
  function gameDayRender() {
    return (
      <Wrapper>
        {isDesktop ? (
          <>
            <img
              width={486}
              height={130}
              style={{ paddingLeft: 25 }}
              src={summer}
              alt="summer logo"
            />
            {count > 0 && <Title>comming up</Title>}
            {count < 0 && <Title>Watch Live!!</Title>}
            <Title>{count > 0 && <h1>{formatTime(count)}</h1>}</Title>
            <Title>
              {count < 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Linker
                    onClick={() =>
                      openInsta(
                        "https://game.naver.com/esports/League_of_Legends/live/lck_2023_summer"
                      )
                    }
                  >
                    <img src={naver} alt="naverIco" width={160}></img>
                  </Linker>
                  <Linker
                    onClick={() =>
                      openInsta(
                        "https://www.youtube.com/live/Phld0aGvjIk?feature=share"
                      )
                    }
                  >
                    <img src={youtube} alt="youtubeIco" width={160}></img>
                  </Linker>
                  <Linker
                    onClick={() =>
                      openInsta("https://play.afreecatv.com/aflol")
                    }
                  >
                    <img src={afreecatv} alt="afreecatvIco" width={160}></img>
                  </Linker>
                </div>
              )}
            </Title>

            <Match>
              <div style={{ paddingRight: 30 }}>
                <Title
                  style={{
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  GAME 1
                </Title>
                {/* 이미지 렌더 유효성 없을시  schedule[0].team1 에러발생 */}
                {schedule.length > 0 && (
                  <Match>
                    <img
                      width={110}
                      height={110}
                      src={`https://assets.lckfantasy.com/logo/${schedule[0].team1.toLowerCase()}.svg`}
                      alt={`${schedule[0].team1}`}
                      style={{
                        paddingRight: 5,
                        margin: 15,
                        objectFit: "contain",
                      }}
                    />
                    <Title>VS</Title>
                    <img
                      width={110}
                      height={110}
                      src={`https://assets.lckfantasy.com/logo/${schedule[0].team2.toLowerCase()}.svg`}
                      alt={`${schedule[0].team2}`}
                      style={{
                        paddingRight: 5,
                        margin: 15,
                        objectFit: "contain",
                      }}
                    />
                  </Match>
                )}
              </div>
              <div>
                <Title
                  style={{
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  GAME 2
                </Title>
                {schedule.length > 0 && (
                  <Match>
                    <img
                      width={110}
                      height={110}
                      src={`https://assets.lckfantasy.com/logo/${schedule[1].team1.toLowerCase()}.svg`}
                      alt={`${schedule[1].team1}`}
                      style={{
                        paddingRight: 5,
                        margin: 15,
                        objectFit: "contain",
                      }}
                    />
                    <Title>VS</Title>
                    <img
                      width={110}
                      height={110}
                      src={`https://assets.lckfantasy.com/logo/${schedule[1].team2.toLowerCase()}.svg`}
                      alt={`${schedule[1].team2}`}
                      style={{
                        paddingRight: 5,
                        margin: 15,
                        objectFit: "contain",
                      }}
                    />
                  </Match>
                )}
              </div>
            </Match>
          </>
        ) : (
          <>
            <img width={243} height={65} src={summer} alt="summer logo" />

            {count > 0 && <Title style={{ fontSize: 32 }}>comming up</Title>}
            {count < 0 && <Title style={{ fontSize: 32 }}>Watch live!</Title>}

            <Title style={{ fontSize: 24 }}>
              {count > 0 && <h1>{formatTime(count)}</h1>}
              {count < 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Linker
                    onClick={() =>
                      openInsta(
                        "https://game.naver.com/esports/League_of_Legends/live/lck_2023_summer"
                      )
                    }
                  >
                    <img src={naver} alt="naverIco" width={80}></img>
                  </Linker>
                  <Linker
                    onClick={() =>
                      openInsta(
                        "https://www.youtube.com/live/7kfeJUZHCIk?feature=share"
                      )
                    }
                  >
                    <img src={youtube} alt="youtubeIco" width={80}></img>
                  </Linker>
                  <Linker
                    onClick={() =>
                      openInsta("https://play.afreecatv.com/aflol")
                    }
                  >
                    <img src={afreecatv} alt="afreecatvIco" width={80}></img>
                  </Linker>
                </div>
              )}
            </Title>
            <Title>GAME 1</Title>
            {schedule.length > 0 && (
              <Match>
                <img
                  width={90}
                  height={90}
                  src={`https://assets.lckfantasy.com/logo/${schedule[0].team1.toLowerCase()}.svg`}
                  alt={`${schedule[0].team1}`}
                  style={{ paddingRight: 5, margin: 15, objectFit: "contain" }}
                />
                <Title>VS</Title>
                <img
                  width={90}
                  height={90}
                  src={`https://assets.lckfantasy.com/logo/${schedule[0].team2.toLowerCase()}.svg`}
                  alt={`${schedule[0].team2}`}
                  style={{ paddingRight: 5, margin: 15, objectFit: "contain" }}
                />
              </Match>
            )}
            <Title>GAME 2</Title>
            {schedule.length > 0 && (
              <Match>
                <img
                  width={90}
                  height={90}
                  src={`https://assets.lckfantasy.com/logo/${schedule[1].team1.toLowerCase()}.svg`}
                  alt={`${schedule[1].team1}`}
                  style={{ paddingRight: 5, margin: 15, objectFit: "contain" }}
                />
                <Title>VS</Title>
                <img
                  width={90}
                  height={90}
                  src={`https://assets.lckfantasy.com/logo/${schedule[1].team2.toLowerCase()}.svg`}
                  alt={`${schedule[1].team2}`}
                  style={{ paddingRight: 5, margin: 15, objectFit: "contain" }}
                />
              </Match>
            )}
          </>
        )}
      </Wrapper>
    );
  }

  // 경기 없는 날 렌더링 함수 weekly best
  function NotGameDayRender() {
    return (
      <Wrapper>
        {isDesktop ? (
          <>
            <img
              width={486}
              height={130}
              style={{ paddingLeft: 25 }}
              src={summer}
              alt="summer logo"
            />
            <Title>WEEKLY BEST</Title>
          </>
        ) : (
          <>
            <img width={243} height={65} src={summer} alt="summer logo" />
            <Title style={{ fontSize: 32 }}>WEEKLY BEST</Title>
          </>
        )}
      </Wrapper>
    );
  }

  // 보여지는 렌더링 구간
  if (gameDay) return gameDayRender();
  if (!gameDay) return NotGameDayRender();
};

export default Home;
const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "lckEN";
  flex-direction: column;
  font-family: "Dohyeon";
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;
const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
  text-decoration: none;
  //color: #4f3a6c;
  font-family: "lckEN";
`;
const Match = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Linker = styled.a`
  margin-left: 35px;
  padding-top: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
