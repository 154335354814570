import React, { useState } from "react";
import styled from "styled-components";
import { InputGroup } from "../components/inputs/InputGroups";
import { Link, useNavigate } from "react-router-dom";
import AlertModal from "../components/modals/AlertModal";
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen } from "../atom/Atoms";
import { instanceAxios } from "../api/axios";

const Login = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  const submitLogIn = async (e) => {
    e.preventDefault();

    try {
      const response = await instanceAxios.post("/user/login", {
        email,
        password,
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        navigate("/page/Fantasy/");

        console.log("로그인 성공🎉");
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log("로그인 실패");
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };
  return (
    <Wrapper>
      <Box>
        <WrapTitle>
          <Title>Sign IN!!</Title>
          Enjoy LCK by LCK Fantasy
        </WrapTitle>
        <SubTitle>Email</SubTitle>
        <EmailWrapper>
          <InputGroup placeholder="Email을 입력해주세요" value={null} />
        </EmailWrapper>
        <SubTitle>Password</SubTitle>
        <EmailWrapper>
          <InputGroup placeholder="비밀번호를 입력해주세요" />
          <SubTitle style={{ width: "100%", fontSize: 16 }}>
            비밀번호는 문자,숫자,특수문자의 조합입니다.
          </SubTitle>
        </EmailWrapper>
        <LoginWrapper>
          <SubTitle
            style={{
              width: 230,
              height: 50,
              paddingTop: 10,
              alignItems: "center",
            }}
          >
            아직 회원이 아닌신가요?
          </SubTitle>
          <LoginButton
            style={{
              width: 150,
              alignItems: "center",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                marginTop: "8px",
              }}
              to={"/signup"}
            >
              Sign Up
            </Link>
          </LoginButton>
        </LoginWrapper>
      </Box>
      {/* alert */}
      {isAlertOpen && <AlertModal></AlertModal>}
      {/* alert */}
    </Wrapper>
  );
};

export default Login;
const Box = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 60px;
`;
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  font-family: "Dohyeon";
`;
const WrapTitle = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  font-family: "Dohyeon";
  line-height: 30px;
  &::after {
    display: block;
    margin-bottom: 18px;

    content: "";
    left: 0;
    width: 100%;
    height: 1px;
    background: #8c8c8c;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: black;
  word-break: break-all;
`;
const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 410px;
  background: white;
`;
const SubTitle = styled.div`
  font-size: 25px;
  width: 100px;
  color: black;
`;
const LoginButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
`;
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 410px;
`;
