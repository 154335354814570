import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { listPageReLoading, focusNav } from "../../atom/Atoms";
import WhiteLogo from "../../assets/images/whiteLogo.png";
import navList from "../../atom/NavList";
import styled from "styled-components";
import Logo from "../../assets/images/lcklogo.png";
import { useMediaQuery } from "react-responsive";
import { hover } from "@testing-library/user-event/dist/hover";
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1120 });
  return isDesktop ? children : null;
};
function NavMobile() {
  const [focusPath, setFocusPath] = useRecoilState(focusNav);
  const [isMenu, setIsMenu] = useState(false);
  const location = useLocation();
  const focus = location.pathname.split("/")[2];
  const bottomFocus = location.pathname.split("/")[3];

  return (
    <>
      <NavContainer>
        <NavWrapper>
          <img src={WhiteLogo} alt="logo" height={64} />
          <div>Fantasy</div>
          {isMenu && (
            <MyRightMenu>
              {navList.map(({ title, path }) => {
                return (
                  <Link
                    key={path}
                    to={`/page/${path}`}
                    style={{ textDecoration: "none" }}
                    onClick={() => setIsMenu(!isMenu)}
                  >
                    <LeftSideLink key={path}>{title}</LeftSideLink>
                  </Link>
                );
              })}
              <Link
                to={"/login"}
                style={{ textDecoration: "none" }}
                onClick={() => setIsMenu(!isMenu)}
              >
                <LeftSideLink>Sign IN</LeftSideLink>
              </Link>
            </MyRightMenu>
          )}
          <div style={{ borderLeft: "1px solid #ccc", paddingLeft: 10 }}>
            <svg
              width="64px"
              height="64px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsMenu(!isMenu)}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 7a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm1 4a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
          </div>
        </NavWrapper>
      </NavContainer>
    </>
  );
}

export default NavMobile;

const HeaderImage = styled.div`
  display: flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-top-right-radius: 75%;
  border-bottom-right-radius: 85%;
  min-height: 155px;
`;
const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavWrapper = styled.div`
  width: 100%;
  height: 65px;
  padding: 10px;
  position: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: #4f3a6c;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  font-size: large;
  align-items: center;
  font-family: "lckEN";
`;
const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;
const LeftSideSelectLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: none;
  height: 65;
  font-family: "lckEN";
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 15px;
`;
const LeftSideLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: none;
  color: #4f3a6c;
  height: 52px;
  font-family: "lckEN";
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 15px;
  }
`;

const MyRightMenu = styled.ul`
  width: 140px;
  position: absolute;
  right: 20px;
  padding-right: 20px;
  top: 68px;
  align-items: cente;
  border-radius: 8px;
  box-shadow: 3px -3px 50px rgba(0, 0, 0, 0.13);
  background-color: white;
  text-align: center;
  font-family: "Pretendard-Regular";

  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-bottom: 28px solid white;
    border-left: 0px solid transparent;
    border-right: 28px solid transparent;
    right: 0;
    top: -14px;
    transform: rotate(270deg);
  }
`;
