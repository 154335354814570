import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { instanceAxios } from "../../api/axios";
import {
  InputGroup,
  InputPasswordValidateGroup,
  InputValidateGroup,
} from "../../components/inputs/InputGroups";
import TeamList from "../../atom/TeamList";
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen } from "../../atom/Atoms";
import AlertModal from "../../components/modals/AlertModal";
const SignUpMobile = () => {
  const navigate = useNavigate();
  const [isOpenTimer, setIsOpenTimer] = useState(false);
  const [isTimer, setIsTimer] = useState(false);
  const [timerId, setTimerId] = useState(null); // 타이머 ID
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState("");
  const [emailVaildation, setEmailVaildation] = useState(false);
  const [tokenVaildation, setTokenVaildation] = useState(false);
  const [passwordVaildation, setPasswordVaildation] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [currentTeam, setCurrentTeam] = useState("");

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  useEffect(() => {
    const disableZoom = () => {
      document.documentElement.style.touchAction = "manipulation";
    };

    disableZoom();

    return () => {
      document.documentElement.style.touchAction = "";
    };
  }, []);

  useEffect(() => {
    let intervalId;

    const startTimer = () => {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(intervalId);
            alert("인증번호 입력시간이 초과하였습니다");
            window.location.reload();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      setTimerId(intervalId);
    };

    if (isTimer) {
      startTimer();
    } else {
      clearInterval(intervalId); // 타이머 멈춤
    }

    return () => {
      clearInterval(intervalId); // 컴포넌트 언마운트 시 타이머 멈춤
    };
  }, [isTimer]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instanceAxios.post("/auth/register", {
        email,
        password,
        username,
        token,
      });
      if (response.status === 200) {
        navigate("/");
        console.log("회원가입 성공🎉");
      }
      console.log(response);
    } catch (err) {
      setErrors(err.response.data || {});
      console.error(err);
    }
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    //setTimer();
    return (
      <span>
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );
  };
  // 이메일 보내기
  const checkEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await instanceAxios.post("/auth/sendToken", { email });

      if (response.status === 200) {
        setIsTimer(true);
        setIsOpenTimer(true);
        setIsAlertOpen(true);
        setAlertMessage("메일로 보낸 인증번호를 확인하세요✔");
        setTokenVaildation(false);
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };
  // 토큰 검증
  const checkToken = async (e) => {
    e.preventDefault();
    try {
      const response = await instanceAxios.post("/auth/checkToken", {
        email,
        token,
      });

      if (response.status === 200) {
        setIsTimer(false);
        setIsOpenTimer(false);
        setTokenVaildation(true);
        setIsAlertOpen(true);
        setAlertMessage("메일 인증에 성공하셨습니다🎉");
        console.log("검증 성공🎉");
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };
  // 이메일 직접 쓰기
  const handleEmail = (e) => {
    e.preventDefault();
    // ***.com 정규식
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let result = re.test(e.target.value);
    console.log(result, "이메일 테스트");
    setEmailVaildation(result);
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    e.preventDefault();
    // ***.com 정규식
    let re = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
    let result = re.test(e.target.value);
    console.log(result, "페스워드 테스트");
    setPasswordVaildation(result);
    setPassword(e.target.value);
  };
  const handleConPassword = (e) => {
    e.preventDefault();
    setConPassword(e.target.value);
  };

  // 토큰 쓰기
  const handleToken = (e) => {
    e.preventDefault();
    setToken(e.target.value);
  };
  // 토큰 쓰기
  const submitSignUp = async (e) => {
    e.preventDefault();

    try {
      const response = await instanceAxios.post("/user/signup", {
        username,
        password,
        email,
        token,
        currentTeam,
      });

      if (response.status === 200) {
        setIsTimer(false);
        setIsOpenTimer(false);
        setTokenVaildation(true);
        setIsAlertOpen(true);
        setAlertMessage("LCK Fantasy 가입을 축하드립니다🎉");
        navigate("/login");
        console.log("가입 성공🎉");
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };

  return (
    <Wrapper>
      <Box>
        <WrapTitle>
          <Title>Sign UP!!</Title>
          Enjoy LCK by LCK Fantasy
        </WrapTitle>
        <form>
          <InputWrapper>
            <SubTitle>Email</SubTitle>
          </InputWrapper>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputValidateGroup
              placeholder="Email"
              value={email}
              setValue={handleEmail}
              error={errors.email}
            />
            {emailVaildation ? (
              <Button active onClick={checkEmail}>
                Email 인증하기
              </Button>
            ) : (
              <Button inactive>Email 인증하기</Button>
            )}
          </div>
          <WrapWriteToken>
            <TokenMsg>
              이메일로 전송된 인증번호를 입력해주세요.{" "}
              {isOpenTimer && formatTime(remainingTime)}
            </TokenMsg>
            <div style={{ display: "flex" }}>
              {tokenVaildation ? (
                <InputValidateGroup
                  placeholder="인증번호를 입력해주세요."
                  readonly
                  value={token}
                  setValue={handleToken}
                  error={errors.token}
                />
              ) : (
                <InputValidateGroup
                  placeholder="인증번호를 입력해주세요."
                  value={token}
                  setValue={handleToken}
                  error={errors.token}
                />
              )}
              {!tokenVaildation ? (
                <Button onClick={checkToken}>인증하기</Button>
              ) : (
                <Button>인증완료</Button>
              )}
            </div>
          </WrapWriteToken>
          <InputWrapper>
            <SubTitle>Username</SubTitle>
            <EmailWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputGroup
                  placeholder="닉네임을 입력해주세요"
                  value={username}
                  setValue={setUsername}
                  error={errors.userName}
                />
              </div>
            </EmailWrapper>
          </InputWrapper>
          <InputWrapper>
            <SubTitle>Password</SubTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputPasswordValidateGroup
                placeholder="비밀번호를 입력해주세요"
                value={password}
                setValue={handlePassword}
                error={errors.password}
              />
              {password.length === 0 || passwordVaildation ? (
                <SubTitle style={{ width: "100%", fontSize: 16 }}>
                  &nbsp;&nbsp; 8~16자 문자, 숫자, 특수문자를 사용하세요
                </SubTitle>
              ) : (
                <SubTitle style={{ width: "100%", fontSize: 16, color: "red" }}>
                  &nbsp;&nbsp; 8~16자 문자, 숫자, 특수문자를 사용하세요
                </SubTitle>
              )}
              <InputPasswordValidateGroup
                placeholder="비밀번호를 확인해주세요"
                value={conPassword}
                setValue={handleConPassword}
                error={errors.conPassword}
              />
              {password !== conPassword && conPassword.length > 0 && (
                <SubTitle style={{ width: "100%", fontSize: 16, color: "red" }}>
                  &nbsp;&nbsp; 같은 비밀번호를 입력해주세요
                </SubTitle>
              )}
            </div>
          </InputWrapper>
          <SubTitle
            style={{
              width: 280,
              paddingTop: 10,
              alignItems: "center",
            }}
          >
            Pick Your Team!!{" "}
          </SubTitle>
          <TeamWrapper>
            {TeamList.map(({ name, path }) => {
              return (
                <Team
                  onClick={() => setCurrentTeam(name)}
                  key={name}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    height={25}
                    width={34}
                    style={{ objectFit: "contain" }}
                    src={`${path}`}
                    alt={`${name}`}
                  />
                </Team>
              );
            })}
          </TeamWrapper>
          <SubTitle
            style={{
              width: 280,
              height: 50,
              paddingTop: 10,
              alignItems: "flex-end",
            }}
          >
            MY Team :
            {currentTeam ? (
              <img
                width={38}
                height={38}
                style={{
                  objectFit: "cover",
                  padding: 5,
                  borderRadius: "50%",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                src={`https://assets.lckfantasy.com/ICONS/${currentTeam.toLowerCase()}.jpg`}
                alt={`${currentTeam}`}
              />
            ) : null}
            &nbsp;
            {currentTeam.toLocaleUpperCase()}
          </SubTitle>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {username &&
            emailVaildation &&
            passwordVaildation &&
            tokenVaildation &&
            currentTeam &&
            password === conPassword ? (
              <Button onClick={submitSignUp} active>
                회원가입
              </Button>
            ) : (
              <Button inactive>회원가입</Button>
            )}
          </div>
        </form>
        <LoginWrapper>
          <SubTitle
            style={{
              width: 230,
              height: 50,
              paddingTop: 10,
              alignItems: "center",
            }}
          >
            이미 가입하셨나요?
          </SubTitle>
          <LoginButton
            style={{
              width: 150,
              alignItems: "center",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                marginTop: "8px",
              }}
              to={"/login"}
            >
              Sign in
            </Link>
          </LoginButton>
        </LoginWrapper>
      </Box>
      {/* alert */}
      {isAlertOpen && <AlertModal></AlertModal>}
      {/* alert */}
    </Wrapper>
  );
};

export default SignUpMobile;
const Box = styled.div`
  background: white;
  width: 100%;
  font-family: "Dohyeon";
`;
const Wrapper = styled.div`
  width: 100%;
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: white;
`;
const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: white;
`;
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;
const WrapWriteToken = styled.div`
  background: #f0f0f0;
  padding: 8px;
`;
const TokenMsg = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  display: block;
  border: none;
  width: 90%;
  margin: 10px;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
  background: ${(props) => (props.inactive ? `#BFBFBF` : null)};
  background: ${(props) => (props.active ? `#4f3a6c` : null)};
  color: ${(props) => (props.inactive ? `black` : null)};
  color: ${(props) => (props.active ? `white` : null)};
  border: 1px solid ${(props) => (props.inactive ? `grey` : "#434343")};
  cursor: ${(props) => (props.inactive ? `default` : null)};
  &:hover {
  }
`;
const LoginButton = styled.button`
  display: block;
  border: none;
  width: 30%;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
`;
const WrapTitle = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  font-family: "Dohyeon";
  line-height: 20px;
  font-size: 13px;
  &::after {
    display: block;

    content: "";
    left: 0;
    width: 100%;
    height: 1px;
    background: #8c8c8c;
  }
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: black;
  word-break: break-all;
`;
const SubTitle = styled.div`
  font-size: 20px;
  width: 20%;
  color: black;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;
const Team = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;
