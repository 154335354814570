import React, { useEffect, useState } from "react";
import PageLayout from "../templates/PageLayout";
import { instanceAxios } from "../api/axios";
import styled from "styled-components";
import { primary4, grey1, grey3, grey5, error3 } from "../constants/color";
import TeamList from "../atom/TeamList";

const Schedule = () => {
  function countWednesdays(startDateString) {
    const startDate = new Date(startDateString);
    const today = new Date();
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // 1일의 밀리초 수

    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= today) {
      if (currentDate.getDay() === 3) {
        // 3은 수요일을 의미합니다.
        count++;
      }
      currentDate.setTime(currentDate.getTime() + millisecondsPerDay); // 다음 날짜로 이동
    }

    return count;
  }

  // 주차 계산 함수
  const startDateString = "2023-06-05";
  const wednesdayCount = countWednesdays(startDateString);

  // 현재 주차 출력
  const [schedule, setSchedule] = useState([]);
  const [isWeek, setIsWeek] = useState(true);
  const [isTeam, setIsTeam] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(wednesdayCount);
  const [currentTeam, setCurrentTeam] = useState("t1");
  useEffect(() => {
    const getSchedule = async () => {
      try {
        const response = await instanceAxios.get("/schedule");
        if (response.status === 200) {
          setSchedule(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getSchedule();
    console.log("Schedule wednesdayCount", wednesdayCount);
  }, []);

  const handleWeek = () => {
    setIsTeam(false);
    setIsWeek(true);
  };
  const handleTeam = () => {
    setIsTeam(true);
    setIsWeek(false);
  };

  return (
    <Wrapper className="schedule-wrapper">
      <ButtonWrapper>
        {isWeek ? (
          <FilterCheckedButton onClick={handleWeek}>WEEK</FilterCheckedButton>
        ) : (
          <FilterButton onClick={handleWeek}>WEEK</FilterButton>
        )}
        {isTeam ? (
          <FilterCheckedButton onClick={handleTeam}>TEAM</FilterCheckedButton>
        ) : (
          <FilterButton onClick={handleTeam}>TEAM</FilterButton>
        )}
      </ButtonWrapper>
      <Wrapper className="teamWrapper">
        {isWeek && (
          <>
            <ButtonWrapper style={{ paddingBottom: 20 }}>
              {currentWeek > 1 ? (
                <svg
                  fill="#000000"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="84px"
                  height="84px"
                  viewBox="-9.76 -9.76 70.91 70.91"
                  xmlSpace="preserve"
                  stroke="#000000"
                  strokeWidth="4.11104"
                  transform="matrix(-1, 0, 0, 1, 0, 0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => setCurrentWeek(currentWeek - 1)}
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M9.169,51.388c-0.351,0-0.701-0.157-0.93-0.463c-0.388-0.514-0.288-1.243,0.227-1.634l31.066-23.598L8.461,2.098 C7.95,1.708,7.85,0.977,8.237,0.463c0.395-0.517,1.126-0.615,1.64-0.225l33.51,25.456L9.877,51.151 C9.664,51.31,9.415,51.388,9.169,51.388z"></path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              ) : (
                <div style={{ width: 84 }}> </div>
              )}
              <div style={{ fontSize: 75 }}>week{currentWeek}</div>
              {currentWeek < 9 ? (
                <svg
                  fill="#000000"
                  version="1.1"
                  id="Capa_1"
                  width="84px"
                  height="84px"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="-9.76 -9.76 70.91 70.91"
                  xmlSpace="preserve"
                  stroke="#000000"
                  strokeWidth="4.11104"
                  onClick={() => setCurrentWeek(currentWeek + 1)}
                  style={{ cursor: "pointer" }}
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M9.169,51.388c-0.351,0-0.701-0.157-0.93-0.463c-0.388-0.514-0.288-1.243,0.227-1.634l31.066-23.598L8.461,2.098 C7.95,1.708,7.85,0.977,8.237,0.463c0.395-0.517,1.126-0.615,1.64-0.225l33.51,25.456L9.877,51.151 C9.664,51.31,9.415,51.388,9.169,51.388z"></path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              ) : (
                <div style={{ width: 84 }}> </div>
              )}
            </ButtonWrapper>
            {schedule?.length > 0
              ? schedule
                  .filter((schedule) => schedule.week === currentWeek)
                  .map(({ id, date, team1, team2, time }) => {
                    const calDate = new Date(date);
                    const daysOfWeek = [
                      "일",
                      "월",
                      "화",
                      "수",
                      "목",
                      "금",
                      "토",
                    ];
                    const dayOfWeek = daysOfWeek[calDate.getDay()];
                    return (
                      <ScheduleTable key={id}>
                        <tr
                          style={{
                            borderTop: "1px solid #ccc",
                            borderBottom: "2px solid #ccc",
                            backgroundColor: "#fafbfe",
                            width: "100%",
                          }}
                        >
                          {id % 2 === 0 ? null : (
                            <td>
                              {date.slice(0, 10)} ({dayOfWeek})
                            </td>
                          )}
                        </tr>
                        <ScheduleTr>
                          <ScheduleTd style={{ paddingRight: "144px" }}>
                            {time}
                          </ScheduleTd>
                          <ScheduleTd style={{ padding: "4px" }}>
                            {" "}
                            {team1}
                          </ScheduleTd>
                          <ScheduleTd style={{ padding: "4px" }}>
                            <img
                              height={80}
                              width={80}
                              src={`https://assets.lckfantasy.com/logo/${team1.toLowerCase()}.svg`}
                              alt={`${team1}`}
                            />
                          </ScheduleTd>
                          <ScheduleTd style={{ padding: "4px", width: 40 }}>
                            {" "}
                            VS{" "}
                          </ScheduleTd>
                          <ScheduleTd style={{ paddingL: "4px" }}>
                            <img
                              height={80}
                              width={80}
                              src={`https://assets.lckfantasy.com/logo/${team2.toLowerCase()}.svg`}
                              alt={`${team2}`}
                            />
                          </ScheduleTd>
                          <ScheduleTd style={{ padding: "4px" }}>
                            {" "}
                            {team2}
                          </ScheduleTd>
                        </ScheduleTr>
                      </ScheduleTable>
                    );
                  })
              : null}
          </>
        )}
        <TeamWrapper>
          {isTeam &&
            TeamList.map(({ name, path }) => {
              return (
                <Team
                  onClick={() => setCurrentTeam(name)}
                  key={name}
                  style={{ cursor: "pointer" }}
                >
                  <img height={32} src={`${path}`} alt={`${name}`} />
                </Team>
              );
            })}
        </TeamWrapper>
        {schedule?.length > 0 &&
          isTeam &&
          schedule
            .filter(
              (schedule) =>
                schedule.team1.toLowerCase() === currentTeam.toLowerCase() ||
                schedule.team2.toLowerCase() === currentTeam.toLowerCase()
            )
            .map(({ id, date, team1, team2, time }) => {
              const calDate = new Date(date);
              const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
              const dayOfWeek = daysOfWeek[calDate.getDay()];
              return (
                <ScheduleTable key={id}>
                  <tr
                    style={{
                      borderBottom: "2px solid #ccc",
                      backgroundColor: "#fafbfe",
                    }}
                  >
                    <td>
                      {date.slice(0, 10)} ({dayOfWeek})
                    </td>
                  </tr>
                  <ScheduleTr>
                    <ScheduleTd style={{ paddingRight: "144px" }}>
                      {time}
                    </ScheduleTd>
                    <ScheduleTd style={{ padding: "4px" }}> {team1}</ScheduleTd>
                    <ScheduleTd style={{ padding: "4px" }}>
                      <img
                        height={80}
                        width={80}
                        src={`https://assets.lckfantasy.com/logo/${team1.toLowerCase()}.svg`}
                        alt={`${team1}`}
                      />
                    </ScheduleTd>
                    <ScheduleTd style={{ padding: "4px", width: 40 }}>
                      {" "}
                      VS{" "}
                    </ScheduleTd>
                    <ScheduleTd style={{ paddingL: "4px" }}>
                      <img
                        height={80}
                        width={80}
                        src={`https://assets.lckfantasy.com/logo/${team2.toLowerCase()}.svg`}
                        alt={`${team2}`}
                      />
                    </ScheduleTd>
                    <ScheduleTd style={{ padding: "4px" }}> {team2}</ScheduleTd>
                  </ScheduleTr>
                </ScheduleTable>
              );
            })}
      </Wrapper>
    </Wrapper>
  );
};

export default Schedule;
const Wrapper = styled.div`
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;
const Team = styled.div`
  display: flex;
  flex: auto;
  padding: 5px;
  width: 100px;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 550px;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
const FilterButton = styled.button`
  display: block;
  border: none;
  width: 180px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 8px;
  background: ${grey1};
  color: ${primary4};
  border: 3px solid ${primary4};
  font-family: "Dohyeon";
  &:hover {
    background: ${primary4};
    color: ${grey1};
  }
`;
const FilterCheckedButton = styled.button`
  display: block;
  border: none;
  width: 180px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 8px;
  border: 3px solid ${primary4};
  background: ${primary4};
  color: ${grey1};
  font-family: "Dohyeon";
`;
const ScheduleTable = styled.table`
  width: 60%;
  display: flex;
  justify-content: space-around;
  font-size: 40px;
  flex-direction: column;
  font-family: "Dohyeon";
  border-collapse: collapse;
`;
const ScheduleTr = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 40px;
  flex-direction: row;
  align-items: center;
  font-family: "Dohyeon";
  border-bottom: 1px solid #ccc;
`;
const ScheduleTd = styled.td`
  display: flex;
  width: 80px;
  padding: 10px;
`;
const ScheduleTeam = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
