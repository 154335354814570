import React, { useState } from "react";
import styled from "styled-components";
import { AlertMessage, IsAlertOpen, MyTeam } from "../atom/Atoms";
import { useRecoilState } from "recoil";
import AlertModal from "./modals/AlertModal";

const Lane = ({ lane, item }) => {
  const [selectPlayer, setSelectPlayer] = useState("");
  const [myTeam, setMyTeam] = useRecoilState(MyTeam);

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  const onSelectPlayer = (name, points, Team) => {
    let lowerLane = lane.toLowerCase();
    const updatedTeam = { ...myTeam }; // myTeam 객체를 복사하여 새로운 객체 생성
    const isTeamAlreadySelected = Object.values(updatedTeam).some(
      (player) => player.Team === Team
    );
    if (isTeamAlreadySelected) {
      setIsAlertOpen(true);
      setAlertMessage("같은 팀 선수를 2명 이상 뽑을 수 없습니다 ⚠");
      return;
    }
    let currentPoints = Object.values(updatedTeam).reduce(
      (sum, player) => sum + player.points,
      0
    );
    const existingPlayerPoints = updatedTeam[lowerLane]
      ? updatedTeam[lowerLane].points
      : 0;
    currentPoints = currentPoints - existingPlayerPoints + points;

    if (currentPoints > 100) {
      setIsAlertOpen(true);
      setAlertMessage("선수들 포인트의 합은 100을 넘길 수 없습니다 ⚠");
      return;
    }
    switch (lowerLane) {
      case "top":
        updatedTeam.top = { name, points, Team };
        break;
      case "jug":
        updatedTeam.jug = { name, points, Team };
        break;
      case "mid":
        updatedTeam.mid = { name, points, Team };
        break;
      case "adc":
        updatedTeam.adc = { name, points, Team };
        break;
      case "sup":
        updatedTeam.sup = { name, points, Team };
        break;
      default:
        break;
    }
    setSelectPlayer(name);
    setMyTeam(updatedTeam); // 변경된 객체로 업데이트
    console.log(myTeam, "드래프트 팀");
  };
  const renderLaneList = (playerList) => {
    return (
      <>
        {playerList.map(({ Player, Team, points }) => {
          return (
            <PlayerSheet
              onClick={() => onSelectPlayer(Player, points, Team)}
              key={Player}
            >
              <LogoWrapper>
                <img
                  width={44}
                  height={35}
                  src={`https://assets.lckfantasy.com/logo/${Team.toLowerCase()}.svg`}
                  alt={`${Team}`}
                  style={{ paddingRight: 5, objectFit: "contain" }}
                />
              </LogoWrapper>
              <PlayerWrapper> {Player}</PlayerWrapper>
              <Points> {points}</Points>
            </PlayerSheet>
          );
        })}
      </>
    );
  };
  return (
    <Wrapper>
      <h1>{lane ? lane : "Lane"}</h1>
      <Tabs>
        <No> Team</No>
        <No> Player</No>
        <No> Points</No>
      </Tabs>
      {renderLaneList(item)}
      {/* alert */}
      {isAlertOpen && <AlertModal></AlertModal>}
      {/* alert */}
    </Wrapper>
  );
};

export default Lane;
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
`;
const PlayerSheet = styled.div`
  height: 72px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid gray;
  &:hover {
    background-color: aliceblue;
  }
`;
const Tabs = styled.div`
  display: flex;
  font-size: 24px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 30px;
  align-items: center;
`;

const No = styled.div`
  width: 100%;
  text-align: start;
`;

const PlayerWrapper = styled.div`
  width: 33%;
  text-align: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 20px;
    background-color: black;
  }
`;
const LogoWrapper = styled.div`
  width: 33%;
  text-align: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    width: 1px;
    height: 20px;
    background-color: black;
  }
`;
const Points = styled.div`
  width: 33%;
  text-align: center;
`;
