import React, { useEffect, useState } from "react";
import { instanceAxios } from "../../api/axios";
import styled from "styled-components";
import { primary4, grey1, grey3, grey5, error3 } from "../../constants/color";
import TeamList from "../../atom/TeamList";
import backGround from "../../assets/images/background.png";
const ScheduleMobile = () => {
  function countWednesdays(startDateString) {
    const startDate = new Date(startDateString);
    const today = new Date();
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // 1일의 밀리초 수

    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= today) {
      if (currentDate.getDay() === 3) {
        // 3은 수요일을 의미합니다.
        count++;
      }
      currentDate.setTime(currentDate.getTime() + millisecondsPerDay); // 다음 날짜로 이동
    }

    return count;
  }

  // 주차 계산 함수
  const startDateString = "2023-06-05";
  const wednesdayCount = countWednesdays(startDateString);

  const [schedule, setSchedule] = useState([]);
  const [isWeek, setIsWeek] = useState(true);
  const [isTeam, setIsTeam] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(wednesdayCount);
  const [currentTeam, setCurrentTeam] = useState("t1");
  useEffect(() => {
    const getSchedule = async () => {
      try {
        const response = await instanceAxios.get("/schedule");
        if (response.status === 200) {
          setSchedule(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getSchedule();
  }, []);

  const handleWeek = () => {
    setIsTeam(false);
    setIsWeek(true);
  };
  const handleTeam = () => {
    setIsTeam(true);
    setIsWeek(false);
  };

  return (
    <Wrapper className="schedule-wrapper">
      {/* 사이즈 85 */}
      <ButtonWrapper
        style={{
          paddingTop: 25,
          backgroundImage: `url(${backGround})`,
        }}
      >
        {isWeek ? (
          <FilterCheckedButton onClick={handleWeek}>WEEK</FilterCheckedButton>
        ) : (
          <FilterButton onClick={handleWeek}>WEEK</FilterButton>
        )}
        {isTeam ? (
          <FilterCheckedButton onClick={handleTeam}>TEAM</FilterCheckedButton>
        ) : (
          <FilterButton onClick={handleTeam}>TEAM</FilterButton>
        )}
      </ButtonWrapper>
      <Wrapper className="teamWrapper">
        {isWeek && (
          <>
            <TeamWrapper
              style={{ paddingBottom: 20, justifyContent: "space-around" }}
            >
              {currentWeek > 1 ? (
                <img
                  src="https://assets.lckfantasy.com/ICONS/arrow-left.png"
                  width={35}
                  height={40}
                  alt="left arrow"
                  onClick={() => setCurrentWeek(currentWeek - 1)}
                />
              ) : (
                <div style={{ width: 35 }}> </div>
              )}
              <div style={{ fontSize: 24 }}>week{currentWeek}</div>
              {currentWeek < 9 ? (
                <img
                  src="https://assets.lckfantasy.com/ICONS/arrow-right.png"
                  alt="right arrow"
                  width={35}
                  height={40}
                  onClick={() => setCurrentWeek(currentWeek + 1)}
                />
              ) : (
                <div style={{ width: 35 }}> </div>
              )}
            </TeamWrapper>
            {schedule?.length > 0
              ? schedule
                  .filter((schedule) => schedule.week === currentWeek)
                  .map(({ id, date, team1, team2, time }) => {
                    const calDate = new Date(date);
                    const daysOfWeek = [
                      "일",
                      "월",
                      "화",
                      "수",
                      "목",
                      "금",
                      "토",
                    ];
                    const dayOfWeek = daysOfWeek[calDate.getDay()];
                    return (
                      <ScheduleTable key={id}>
                        <tr
                          style={{
                            borderBottom: "1px solid #ccc",
                            backgroundColor: "#fafbfe",
                          }}
                        >
                          {id % 2 === 0 ? null : (
                            <td>
                              {dayOfWeek}-{date.slice(5, 7)}월
                              {date.slice(8, 10)}일
                            </td>
                          )}
                        </tr>
                        <ScheduleTr>
                          <ScheduleTd
                            style={{ alignItems: "center", fontSize: 40 }}
                          >
                            {time}
                          </ScheduleTd>
                          <ScheduleTd style={{ padding: "4px" }}>
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "4px",
                                  fontSize: 32,
                                }}
                              >
                                <img
                                  width={48}
                                  height={48}
                                  src={`https://assets.lckfantasy.com/logo/${team1.toLowerCase()}.svg`}
                                  alt={`${team1}`}
                                  style={{
                                    paddingRight: 5,
                                    objectFit: "contain",
                                  }}
                                />
                                {team1}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontSize: 32,
                                  padding: "4px",
                                }}
                              >
                                <img
                                  width={48}
                                  height={48}
                                  src={`https://assets.lckfantasy.com/logo/${team2.toLowerCase()}.svg`}
                                  alt={`${team2}`}
                                  style={{
                                    paddingRight: 5,
                                    objectFit: "contain",
                                  }}
                                />
                                {team2}
                              </div>
                            </div>
                          </ScheduleTd>
                        </ScheduleTr>
                      </ScheduleTable>
                    );
                  })
              : null}
          </>
        )}
        <TeamWrapper>
          {isTeam &&
            TeamList.map(({ name, path }) => {
              return (
                <div style={{ paddingLeft: 10, width: "12%" }}>
                  <Team
                    onClick={() => setCurrentTeam(name)}
                    key={name}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      width={38}
                      height={38}
                      style={{ objectFit: "cover" }}
                      src={`https://assets.lckfantasy.com/ICONS/${name}.jpg`}
                      alt={`${name}`}
                    />
                  </Team>
                </div>
              );
            })}
        </TeamWrapper>
        {schedule?.length > 0 &&
          currentTeam &&
          schedule
            .filter(
              (schedule) =>
                schedule.team1.toLowerCase() === currentTeam.toLowerCase() ||
                schedule.team2.toLowerCase() === currentTeam.toLowerCase()
            )
            .map(({ id, date, team1, team2, time }) => {
              const calDate = new Date(date);
              const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
              const dayOfWeek = daysOfWeek[calDate.getDay()];
              return (
                <ScheduleTable key={id}>
                  <tr
                    style={{
                      borderBottom: "1px solid #ccc",
                      backgroundColor: "#fafbfe",
                    }}
                  >
                    <td>
                      {dayOfWeek}-{date.slice(5, 7)}월{date.slice(8, 10)}일
                    </td>
                  </tr>
                  <ScheduleTr>
                    <ScheduleTd style={{ alignItems: "center", fontSize: 40 }}>
                      {time}
                    </ScheduleTd>
                    <ScheduleTd style={{ padding: "4px" }}>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "4px",
                            fontSize: 32,
                          }}
                        >
                          <img
                            width={48}
                            height={48}
                            src={`https://assets.lckfantasy.com/logo/${team1.toLowerCase()}.svg`}
                            alt={`${team1}`}
                            style={{ paddingRight: 5, objectFit: "contain" }}
                          />
                          {team1}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: 32,
                            padding: "4px",
                          }}
                        >
                          <img
                            width={48}
                            height={48}
                            src={`https://assets.lckfantasy.com/logo/${team2.toLowerCase()}.svg`}
                            alt={`${team2}`}
                            style={{ paddingRight: 5, objectFit: "contain" }}
                          />
                          {team2}
                        </div>
                      </div>
                    </ScheduleTd>
                  </ScheduleTr>
                </ScheduleTable>
              );
            })}
      </Wrapper>
    </Wrapper>
  );
};

export default ScheduleMobile;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
`;
const Team = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-direction: row;
  height: 45px;
  overflow: auto;
  align-items: center;
  width: 100%;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.106);
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  background-image: url(backGround);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
const FilterButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${primary4};
  color: ${grey1};
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  font-family: "Dohyeon";
  &:hover {
    background: ${primary4};
    color: ${grey1};
  }
`;
const FilterCheckedButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  height: 60px;
  margin-left: 15px;
  z-index: 10;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  background: ${grey1};
  color: ${primary4};
  font-family: "Dohyeon";
`;
const ScheduleTable = styled.table`
  width: 90%;
  display: flex;
  justify-content: space-around;
  font-size: 40px;
  flex-direction: column;
  font-family: "Dohyeon";
  border-collapse: collapse;
`;
const ScheduleTr = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 40px;
  flex-direction: row;
  font-family: "Dohyeon";
  border-bottom: 1px solid #ccc;
`;
const ScheduleTd = styled.td`
  display: flex;
  width: 80px;
  padding: 10px;
`;
const ScheduleTeam = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
