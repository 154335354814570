const LaneList = [
  {
    lane: "TOP",
    path: "https://assets.lckfantasy.com/lane/top.svg",
  },
  {
    lane: "JUG",
    path: "https://assets.lckfantasy.com/lane/jug.svg",
  },
  {
    lane: "MID",
    path: "https://assets.lckfantasy.com/lane/mid.svg",
  },
  {
    lane: "ADC",
    path: "https://assets.lckfantasy.com/lane/adc.svg",
  },
  {
    lane: "SUP",
    path: "https://assets.lckfantasy.com/lane/sup.svg",
  },
];

export default LaneList;
