import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import PageLayout from "../../templates/PageLayout";
import styled from "styled-components";
import backGround from "../../assets/images/background.png";
import { primary4, grey1, grey3, grey5, error3 } from "../../constants/color";

const FantasyMobile = () => {
  const location = useLocation();
  const focus = location.pathname.split("/Fantasy/")[1];

  return (
    <>
      <Outlet />
      {focus ? null : (
        <>
          <PageLayout></PageLayout>
          <Wrapper>
            <ButtonWrapper
              style={{
                paddingTop: 25,
                backgroundImage: `url(${backGround})`,
              }}
            >
              <FilterCheckedButton>Fantasy</FilterCheckedButton>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={"/page/Fantasy/draft"}
              >
                <FilterButton>Draft</FilterButton>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={"/page/Fantasy/standings"}
              >
                <FilterButton>Standings</FilterButton>
              </Link>
            </ButtonWrapper>
            <FantasyWrapper>Fantasy</FantasyWrapper>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default FantasyMobile;
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.106);
`;
const NavFantasy = styled.div`
  padding-left: 350px;
  display: flex;
  width: 100%;
  flex-direction: row;
  text-decoration: none;
  color: #a6a6b9;
  height: 65px;
  font-family: "lckEN";
`;
const NavbottomContent = styled.div`
  padding: 8px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #4f3a6c;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 13px;
  }
`;
const FantasyWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-direction: row;
  height: 45px;
  overflow: auto;
  align-items: center;
  width: 100%;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.106);
  justify-content: space-between;
`;
const NavbottomFocusContent = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  color: #4f3a6c;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 13px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  background-image: url(backGround);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-family: "Dohyeon";
`;
const FilterButton = styled.button`
  display: block;
  border: none;
  width: 100px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${primary4};
  color: ${grey1};
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  font-family: "Dohyeon";
  &:hover {
    background: ${primary4};
    color: ${grey1};
  }
`;
const FilterCheckedButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  height: 60px;
  margin-left: 15px;
  z-index: 10;
  cursor: pointer;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 3px solid ${primary4};
  border-left: 3px solid ${primary4};
  border-right: 3px solid ${primary4};
  background: ${grey1};
  color: ${primary4};
  font-family: "Dohyeon";
`;
