import t1 from "../assets/images/t1.png";
import drx from "../assets/images/drx.svg";
import fb from "../assets/images/FB.png";
import geng from "../assets/images/geng.svg";
import HLE from "../assets/images/HLE.svg";
import KF from "../assets/images/KF.png";
import kt from "../assets/images/kt.svg";
import NS from "../assets/images/NS.png";
import SB from "../assets/images/SB.svg";
import dk from "../assets/images/dk.svg";

const TeamList = [
  {
    name: "t1",
    path: t1,
    href: "https://instagram.com/t1lol",
  },
  {
    name: "gen",
    path: geng,
    href: "https://www.instagram.com/gengesports/",
  },
  {
    name: "dk",
    path: dk,
    href: "https://instagram.com/dpluskia.esports",
  },
  {
    name: "hle",
    path: HLE,
    href: "https://www.instagram.com/hle.official/",
  },
  {
    name: "kt",
    path: kt,
    href: "https://www.instagram.com/ktrolstagram/",
  },
  {
    name: "drx",
    path: drx,
    href: "https://www.instagram.com/drxglobal/",
  },

  {
    name: "bro",
    path: fb,
    href: "https://www.instagram.com/brionesports/",
  },
  {
    name: "lsb",
    path: SB,
    href: "https://www.instagram.com/liivsandbox/",
  },
  {
    name: "ns",
    path: NS,
    href: "https://www.instagram.com/ns_redforce/",
  },
  {
    name: "kdf",
    path: KF,
    href: "https://www.instagram.com/kdf_lol/",
  },
];
export default TeamList;
