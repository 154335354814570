import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

const PageWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  margin: "10px auto";
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;

const PageLayout = () => {
  return <PageWrapper></PageWrapper>;
};

export default PageLayout;
