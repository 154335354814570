import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import PageLayout from "../../templates/PageLayout";
import styled from "styled-components";
import Draft from "./Draft";

const Fantasy = () => {
  const location = useLocation();
  const focus = location.pathname.split("/Fantasy/")[1];

  return (
    <>
      <Outlet />
      {focus ? null : (
        <>
          <PageLayout></PageLayout>
          <Wrapper>
            <NavFantasy>
              <div style={{ width: 180 }}></div>
              <NavbottomFocusContent>fantasy</NavbottomFocusContent>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#a6a6b9",
                  marginTop: "8px",
                }}
                to={"/page/Fantasy/standings"}
              >
                <NavbottomContent>standings</NavbottomContent>
              </Link>
            </NavFantasy>
            <Draft />
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Fantasy;
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Dohyeon";
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;
const NavFantasy = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-decoration: none;
  color: #a6a6b9;
  height: 65px;
  font-family: "lckEN";
`;
const NavbottomContent = styled.div`
  padding: 8px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #4f3a6c;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 13px;
  }
`;
const NavbottomFocusContent = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  color: #4f3a6c;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 13px;
`;
