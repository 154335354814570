const navList = [
  {
    title: "Fantasy",
    path: "Fantasy",
  },
  //  {
  //   title : "Video",
  //   path: "Video"
  // },
  {
    title: "Stats",
    path: "Stats",
  },
  // {
  //   title : "NEWS",
  //   path: "News"
  // },
  {
    title: "Schedule",
    path: "Schedule",
  },
];

export default navList;
