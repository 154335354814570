// 새로운 팔레트
// Primary
export const primary1 = "#AF72FD";
export const primary2 = "#AF72FD";
export const primary3 = "#964CF4";
export const primary4 = "#4f3a6c";
export const primary5 = "#560FB0";
export const primary6 = "#39047C";

// Grey
export const grey1 = "#FFFFFF";
export const grey2 = "#FAFAFA";
export const grey3 = "#F0F0F0";
export const grey4 = "#D9D9D9";
export const grey5 = "#BFBFBF";
export const grey6 = "#8C8C8C";
export const grey7 = "#595959";
export const grey8 = "#434343";
export const grey9 = "#262626";
export const grey10 = "#141414";
export const grey11 = "#000000";

// Error
export const error1 = "#F99191";
export const error2 = "#F99191";
export const error3 = "#E82A2A";
export const error4 = "#C81717";
export const error5 = "#9B0808";

export const MAIN_BACKGROUND_COLOR =
  "linear-gradient(133.78deg, #88EAFF 8.16%, #98A2FF 74.57%)";
